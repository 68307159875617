<template>
  <div v-html="text" />
</template>

<script>
export default {
  name: 'TextView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    text() {
      return this.settings.find((el) => el.techName === 'simpleTextValue').value;
    },
  },
};
</script>
